<template>
  <div class="grant-search-filter-entry" :class="{ popout: asPopout }">
    <div class="entry" @click="clickEntry()">
      <div class="entry__title" v-show="!asPopout">
        {{ cmsTranslationByKey(filterEntityEntry.titleKey) }}
        <div
          class="badge grant-show-mobile"
          v-show="grantCheckedFilterValuesForEntity.length"
        >
          {{ grantCheckedFilterValuesForEntity.length }}
        </div>
      </div>

      <div class="entry__toggle" :class="{ popout: asPopout }">
        <div class="grant-hide-mobile">
          <img v-if="collapsed" src="/img/arrow-down-light.svg" alt="fold" />
          <img v-else src="/img/arrow-up-light.svg" alt="fold" />
        </div>
        <div class="grant-show-mobile">
          <img v-if="collapsed" src="/img/arrow-right.svg" alt="fold" />
        </div>
      </div>
    </div>

    <div
      class="filter-input-search-form"
      v-show="
        showSearchFilterInput &&
        filterEntityEntry.type !== 'date' &&
        (filterEntityEntry.numLevels > 1 ||
          filterEntityEntry.type === 'person' ||
          filterEntityEntry.manyValues)
      "
    >
      <GrantSearchFilterResetButton
        class="reset-entity-filters-button"
        v-show="filterEntriesForEntity(filterEntityId).length > 0"
        @reset-filters="$emit('reset-entity-filters', filterEntityId)"
      ></GrantSearchFilterResetButton>
      <input
        class="input search-input"
        type="text"
        :value="filterInput"
        @input="debounceFilterInput($event.target.value)"
        :placeholder="cmsTranslationByKey('GrantFilterSearch')"
      />
      <button
        v-show="filterInput"
        class="filter-input-search-button"
        @click="resetFilterInput()"
      >
        <img
          src="/img/reset-search.svg"
          class="svg-blue-font-filter"
          alt="reset-search"
        />
      </button>
    </div>

    <div v-if="!collapsed">
      <div v-if="filterEntityEntry.type === 'date'" class="date-filter">
        {{ cmsTranslationByKey("From") }}
        <div class="select">
          <label>
            <select
              class="grant-select"
              v-model="selectedMinYear"
              @change="selectDateValueChanged"
            >
              <option
                v-for="option in filterData"
                :value="option.value"
                :key="option.value"
              >
                {{ option.title }}
              </option>
            </select>
          </label>
        </div>

        {{ cmsTranslationByKey("To") }}
        <div class="select">
          <label>
            <select
              class="grant-select"
              v-model="selectedMaxYear"
              @change="selectDateValueChanged"
            >
              <option
                v-for="option in filterData"
                :value="option.value"
                :key="option.value"
              >
                {{ option.title }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <GrantSearchFilterEntryLevel
        v-if="['filter', 'person', 'output'].includes(filterEntityEntry.type)"
        :filter-data="filterData"
        :filter-input="filterInput"
        :filter-entity-entry="filterEntityEntry"
        :level="1"
        :as-popout="asPopout"
        :show-loader="showLoader"
        @toggle-search-filter="toggleSearchFilter"
        @toggle-fold="toggleFold"
        @filter-show-all="filterShowAll"
      ></GrantSearchFilterEntryLevel>
    </div>

    <div
      v-if="!collapsed && filterEntityEntry.numLevels > 1 && !asPopout"
      class="entry show-more"
      @click="$emit('filter-show-all', filterEntityId, filterData)"
    >
      <div class="entry-checkbox">
        <img
          :src="
            filterEntityEntry.id === 'filterCountry'
              ? '/img/plus.svg'
              : '/img/hierarchy.svg'
          "
          width="16"
          height="16"
          alt="open"
        />
      </div>
      <div class="entry-text">
        <span v-if="filterEntityEntry.id === 'filterCountry'">
          {{ cmsTranslationByKey("ShowAllValues") }}
        </span>
        <span v-else>{{ cmsTranslationByKey("FilterOpenHierarchy") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from "loglevel";
import axios from "axios";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { cmsTranslationMixin, highlighterMixin } from "@/mixins";
import * as _ from "lodash";
import {
  facetResultToObject,
  GRANT_FILTER_MAPPING,
  makeSolrPostRequest,
  OUTPUT_DATA_TYPES,
  splitTrimWords,
} from "@/utils";
import GrantSearchFilterEntryLevel from "@/components/GrantSearchFilterEntryLevel";
import GrantSearchFilterResetButton from "@/components/GrantSearchFilterResetButton";

export default {
  name: "GrantSearchFilterEntry",
  mixins: [cmsTranslationMixin, highlighterMixin],
  props: {
    filterEntityId: {
      type: String,
      default: "",
    },
    outerSolrResponse: {
      type: Object,
      default: null,
    },
    outerSolrQueryQ: {
      type: Object,
      default: null,
    },
    initialCollapsed: {
      type: Boolean,
      default: true,
    },
    initialFilterData: {
      type: Array,
      default: null,
    },
    showSearchFilterInput: {
      type: Boolean,
      default: false,
    },
    asPopout: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
  components: { GrantSearchFilterResetButton, GrantSearchFilterEntryLevel },
  data: function () {
    return {
      filterData: [],
      collapsed: this.initialCollapsed,
      filterInput: "",
      filterInputPerson: "",
      solrResponse: null,
      axiosCancelTokenSource: null,

      selectedMinYear: null,
      selectedMaxYear: null,
      minYear: null,
      maxYear: null,
    };
  },
  watch: {
    "outerSolrResponse.facet_counts": function (val) {
      log.debug("watch solrGrantResponse.facet_counts");
      this.solrResponse = this.outerSolrResponse;
      if (
        // prettier-ignore
        this.filterEntityId === this.grantCheckedLastFilterEntryChange ||
          this.asPopout
          // DSS-1327: handle `filterCountry` special, it should update even in asPopout
          && this.filterEntityId !== 'filterCountry'
      ) {
        log.info("do not reload filter data, because the same filter changed");

        if (this.filterEntityEntry.type === "person") {
          // add the selected person if it is not already in the `filterData` list
          const relevantGrantCheckedFilterEntry = _.find(
            this.grantCheckedFilterEntries,
            (e) => {
              return e.filterEntity.id === this.filterEntityId && e.filterStatusEntry;
            },
          );
          if (relevantGrantCheckedFilterEntry) {
            const filterDataValues = _.map(this.filterData, "value");
            if (
              !_.includes(
                filterDataValues,
                relevantGrantCheckedFilterEntry.filterStatusEntry.value,
              )
            ) {
              log.info(
                "added entry to filterData",
                relevantGrantCheckedFilterEntry.filterStatusEntry,
              );
              this.filterData.push(
                _.clone(relevantGrantCheckedFilterEntry.filterStatusEntry),
              );
              this.filterData = _.orderBy(this.filterData, ["numResults"], ["desc"]);
            }
          }
        }
      } else {
        this.loadFilterData();
      }
    },
  },
  mounted: function () {
    log.debug("GrantSearchFilterEntry mounted");
    this.solrResponse = this.outerSolrResponse;

    if (
      !this.asPopout &&
      this.filterEntityEntry &&
      this.filterEntityEntry.id === "filterFundingInstrument" &&
      this.grantCheckedFilterValues.length === 0
    ) {
      this.collapsed = false;
    }

    if (this.filterEntityEntry.type === "date") {
      this.loadDateFilterData();
    } else if (this.initialFilterData) {
      this.filterData = _.map(this.initialFilterData, (fd) => {
        return _.assign({}, fd, { collapsed: fd.level >= 2 });
      });
    } else if (this.solrResponse) {
      this.loadFilterData();
    }
  },
  computed: {
    ...mapState(["grantDateMinMax"]),
    ...mapGetters([
      "selectedLanguage",
      "grantFilterData",
      "grantCheckedFilterValues",
      "grantPersonNames",
      "grantCheckedFilterEntries",
      "grantCheckedLastFilterEntryChange",
      "isMobile",
      "isMobileOrTablet",
      "screenSize",
      "findGrantFilterValue",
      "filterEntriesForEntity",
      "selectedLanguage",
    ]),
    filterEntityEntry: function () {
      return _.find(GRANT_FILTER_MAPPING, { id: this.filterEntityId });
    },
    grantCheckedFilterValuesForEntity: function () {
      return _.flatMap(
        _.filter(
          this.grantCheckedFilterEntries,
          (fe) => fe.filterEntity.id === this.filterEntityId,
        ),
        "values",
      );
    },
  },
  methods: {
    ...mapActions(["loadPersonNames"]),
    ...mapMutations([
      "setGrantShowFilterMobile",
      "updateGrantFilterValueCache",
      "setMinMaxYears",
    ]),
    loadFilterData() {
      if (this.filterEntityEntry.type === "person") {
        this.loadPersonFilterData();
      } else if (this.filterEntityEntry.type === "output") {
        this.loadOutputFilterData();
      } else if (this.filterEntityEntry.type === "date") {
        this.loadDateFilterData();
      } else {
        this.loadSolrEntityFilterData();
      }
    },
    loadPersonFilterData() {
      if (this.solrResponse) {
        const solrField = this.filterEntityEntry.filterSolrFields[0];
        const personResults = facetResultToObject(
          this.solrResponse.facet_counts.facet_fields[solrField],
        );
        const personIds = _.keys(personResults);

        const queryKeys = _.keys(this.$route.query);
        if (_.includes(queryKeys, this.filterEntityEntry.urlQueryParams[0])) {
          if (!this.isMobileOrTablet) {
            this.collapsed = false;
          }
        }

        if (this.axiosCancelTokenSource) {
          this.axiosCancelTokenSource.cancel("make new search");
          this.axiosCancelTokenSource = null;
        }
        this.axiosCancelTokenSource = axios.CancelToken.source();

        this.loadPersonNames(personIds, this.axiosCancelTokenSource).then(() => {
          const data = _.map(personIds, (pid) => {
            const personNameData = _.find(this.grantPersonNames, {
              PersonId: pid,
            });
            if (personNameData) {
              return {
                numResults: personResults[pid],
                filterSolrField: solrField,
                filterEntityId: this.filterEntityId,
                urlQueryParam: this.filterEntityEntry.urlQueryParams[0],
                entityTitleKey: this.filterEntityEntry.titleKey,
                level: 1,
                title: `${personNameData.Surname} ${personNameData.FirstName}`,
                value: pid,
                collapsed: true,
                children: [],
                childrenTitles: "",
              };
            }
            return null;
          }).filter((entry) => entry);

          this.filterData = _.orderBy(data, ["numResults"], ["desc"]);
          this.updateGrantFilterValueCache(this.filterData);
        });
      }
    },
    loadOutputFilterData() {
      if (this.solrResponse) {
        const gfm = this.filterEntityEntry;

        if (
          _.intersection(
            this.grantCheckedFilterValues,
            _.flatMap(OUTPUT_DATA_TYPES, "id"),
          ).length
        ) {
          this.collapsed = false;
        }

        this.filterData = _.map(OUTPUT_DATA_TYPES, (outputType) => {
          const filterEntry = _.find(this.grantFilterData.response.docs, {
            OutputdataTypeId: outputType.id,
          });
          return {
            numResults: 0,
            filterSolrField: outputType.grantSolrField,
            filterEntityId: this.filterEntityId,
            urlQueryParam: gfm.urlQueryParams[0],
            level: 1,
            title: this.objectAttributeInLanguage(filterEntry, "OutputdataType"),
            value: outputType.id,
            collapsed: true,
            children: [],
            childrenTitles: "",
            hideNumResults: true,
          };
        });
      }
    },
    loadDateFilterData() {
      if (this.solrResponse) {
        const gfm = this.filterEntityEntry;

        this.minYear =
          this.grantDateMinMax[this.filterEntityEntry.grantSolrFields[0]].minYear;
        this.maxYear =
          this.grantDateMinMax[this.filterEntityEntry.grantSolrFields[0]].maxYear;

        const relevantGrantCheckedFilterEntry = _.find(
          this.grantCheckedFilterEntries,
          (e) => {
            return e.filterEntity.id === this.filterEntityId;
          },
        );
        if (relevantGrantCheckedFilterEntry) {
          const years = _.split(relevantGrantCheckedFilterEntry.values[0], ":");
          this.selectedMinYear = years[0] === "*" ? this.minYear : parseInt(years[0]);
          this.selectedMaxYear = years[1] === "*" ? this.maxYear : parseInt(years[1]);
        } else {
          this.selectedMinYear = this.minYear;
          this.selectedMaxYear = this.maxYear;
        }

        this.setMinMaxYears({
          queryParam: gfm.urlQueryParams[0],
          minYear: this.minYear,
          maxYear: this.maxYear,
        });

        const queryKeys = _.keys(this.$route.query);
        if (_.includes(queryKeys, this.filterEntityEntry.urlQueryParams[0])) {
          if (!this.isMobileOrTablet) {
            this.collapsed = false;
          }
        }

        this.filterData = _.map(_.range(this.minYear, this.maxYear + 1), (year) => {
          return {
            filterSolrField: gfm.filterSolrFields[0],
            filterEntityId: this.filterEntityId,
            urlQueryParam: gfm.urlQueryParams[0],
            title: `${year}`,
            value: year,
            hideNumResults: true,
          };
        });
      }
    },
    loadSolrEntityFilterData() {
      if (this.grantFilterData && this.solrResponse) {
        const gfm = this.filterEntityEntry;

        let facetResultObject = {};
        gfm.grantSolrFields.forEach((facetField) => {
          facetResultObject = _.assign(
            {},
            facetResultObject,
            facetResultToObject(
              this.solrResponse.facet_counts.facet_fields[facetField],
            ),
          );
        });

        // FIXME: WORKAROUND for CF-820 -> not all fields level (2?) fields are present in the data from SOLR
        let data = this.grantFilterData.response.docs
          .filter((fd) => {
            return fd.Entity === gfm.entity;
          })
          .map((fd) => {
            let level = 1;
            let parentFieldKey = "";
            let parentFieldValue = "";

            if (gfm.numLevels > 1) {
              // check level by looking if the fields of '...Level2' etc. exist
              if (fd[gfm.filterSolrFields[2]]) {
                level = 3;
              } else if (fd[gfm.filterSolrFields[1]]) {
                level = 2;
              }
              parentFieldKey = gfm.filterSolrFields[level - 2];
              parentFieldValue = fd[parentFieldKey];
            }

            const filterSolrField = gfm.filterSolrFields[level - 1];

            return _.assign({}, fd, {
              level: level,
              parentFieldKey: parentFieldKey,
              parentFieldValue: parentFieldValue,
              filterSolrField: filterSolrField,
            });
          });
        data = _.orderBy(data, ["level"], ["asc"]);

        let data2 = [];
        _.forEach(data, (fd) => {
          data2.push(fd);

          if (fd.level === 3 && fd.Entity === "Filter_Discipline") {
            const l2Entry = _.find(data2, (d) => {
              return (
                d[fd.parentFieldKey] === fd.parentFieldValue &&
                !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
              );
            });

            if (!l2Entry) {
              data2.push(
                _.omit(fd, [
                  "DisciplineLevel3",
                  "DisciplineLevel3Id",
                  "DisciplineLevel3_De",
                  "DisciplineLevel3_Fr",
                  "DisciplineLevel3_En",
                ]),
              );
            }
          }

          if (fd.level === 2 && fd.Entity === "Filter_ResearchInstitution") {
            const l1Entry = _.find(data2, (d) => {
              return (
                d[fd.parentFieldKey] === fd.parentFieldValue &&
                !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
              );
            });

            if (!l1Entry) {
              data2.push(
                _.omit(fd, [
                  "ResearchInstitution",
                  "ResearchInstitutionId",
                  "ResearchInstitution_De",
                  "ResearchInstitution_Fr",
                ]),
              );
            }
          }

          if (fd.level === 2 && fd.Entity === "Filter_Country") {
            const l1Entry = _.find(data2, (d) => {
              return (
                d[fd.parentFieldKey] === fd.parentFieldValue &&
                !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
              );
            });

            if (!l1Entry) {
              data2.push(
                _.omit(fd, ["CountryIsoCodeType", "Type_En", "Type_De", "Type_Fr"]),
              );
            }
          }

          if (fd.level === 2 && fd.Entity === "Filter_Call") {
            if (!fd.CallDecisionYear) {
              // find the year inside the title
              const year = fd.CallFullTitle.match(/\d{4}/);
              if (year) {
                fd.CallDecisionYear = parseInt(year[0]);
                fd.parentFieldValue = fd.CallDecisionYear;
              }
            }

            const l1Entry = _.find(data2, (d) => {
              return (
                d[fd.parentFieldKey] === fd.parentFieldValue &&
                !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
              );
            });

            if (!l1Entry) {
              data2.push(
                _.omit(fd, [
                  "CallId",
                  "CallFullTitle",
                  "CallFullTitle_De",
                  "CallFullTitle_Fr",
                ]),
              );
            }
          }

          if (fd.level === 2 && fd.Entity === "Filter_OutputdataTypes") {
            const l1Entry = _.find(data2, (d) => {
              return (
                d[fd.parentFieldKey] === fd.parentFieldValue &&
                !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
              );
            });

            if (!l1Entry) {
              data2.push(
                _.omit(fd, [
                  "OutputSubtypeId",
                  "OutputSubtype",
                  "OutputSubtype_De",
                  "OutputSubtype_Fr",
                ]),
              );
            }
          }

          if (fd.Entity === "Filter_FundingInstrument") {
            if (fd.level === 3) {
              // FIXME: CF-836: check "Projektförderung (spezial)"
              const l2Entry = _.find(data2, (d) => {
                return (
                  d[fd.parentFieldKey] === fd.parentFieldValue &&
                  !Object.prototype.hasOwnProperty.call(d, fd.filterSolrField)
                );
              });

              if (!l2Entry) {
                data2.push(
                  _.omit(fd, [
                    "FundingInstrumentPublishedId_Frontend",
                    "FundingInstrumentPublished_Frontend",
                    "FundingInstrumentPublished_Frontend_Fr",
                    "FundingInstrumentPublished_Frontend_De",
                  ]),
                );
              }

              const l1Entry = _.find(data2, (d) => {
                return (
                  d.FundingInstrumentLevel1Id === fd.FundingInstrumentLevel1Id &&
                  !Object.prototype.hasOwnProperty.call(
                    d,
                    "FundingInstrumentReportingId",
                  )
                );
              });

              if (!l1Entry) {
                data2.push(
                  _.omit(fd, [
                    "FundingInstrumentPublishedId_Frontend",
                    "FundingInstrumentPublished_Frontend",
                    "FundingInstrumentPublished_Frontend_Fr",
                    "FundingInstrumentPublished_Frontend_De",
                    "FundingInstrumentReporting",
                    "FundingInstrumentReportingId",
                    "FundingInstrumentReporting_Fr",
                    "FundingInstrumentReporting_De",
                  ]),
                );
              }
            }

            if (fd.level === 2) {
              // FIXME: CF-901: level2 data alone was also missing
              const l1Entry = _.find(data2, (d) => {
                return (
                  d.FundingInstrumentLevel1Id === fd.FundingInstrumentLevel1Id &&
                  !Object.prototype.hasOwnProperty.call(
                    d,
                    "FundingInstrumentReportingId",
                  )
                );
              });

              if (!l1Entry) {
                data2.push(
                  _.omit(fd, [
                    "FundingInstrumentPublishedId_Frontend",
                    "FundingInstrumentPublished_Frontend",
                    "FundingInstrumentPublished_Frontend_Fr",
                    "FundingInstrumentPublished_Frontend_De",
                    "FundingInstrumentReporting",
                    "FundingInstrumentReportingId",
                    "FundingInstrumentReporting_Fr",
                    "FundingInstrumentReporting_De",
                  ]),
                );
              }
            }
          }
        });
        // end WORKAROUND

        data = data2.map((fd) => {
          // calc level of filter-entity
          let level = 1;
          let parentFieldKey = "";
          let parentFieldValue = "";
          let parentFieldTitle = "";
          let parentUrlQueryParam = "";
          if (gfm.numLevels > 1) {
            // check level by looking if the fields of '...Level2' etc. exist
            if (fd[gfm.filterSolrFields[2]]) {
              level = 3;
            } else if (fd[gfm.filterSolrFields[1]]) {
              level = 2;
            }
            parentFieldKey = gfm.filterSolrFields[level - 2];
            parentFieldValue = fd[parentFieldKey];
            parentFieldTitle = this.objectAttributeInLanguage(
              fd,
              gfm.titleFields[level - 2],
            );
            parentUrlQueryParam = gfm.urlQueryParams[level - 2];
          }

          const filterFacetField = gfm.filterSolrFields[level - 1];
          const index = fd[filterFacetField];
          const numResults = facetResultObject[index] ? facetResultObject[index] : 0;
          // console.log(numResults)
          // debugger;

          const filterSolrField = gfm.filterSolrFields[level - 1];

          return _.assign({}, fd, {
            numResults: numResults,
            filterSolrField: filterSolrField,
            filterEntityId: this.filterEntityId,
            urlQueryParam: gfm.urlQueryParams[level - 1],
            level: level,
            entityTitleKey: gfm.titleKey,
            title: this.objectAttributeInLanguage(fd, gfm.titleFields[level - 1]),
            value: fd[filterSolrField],
            parentFieldKey: parentFieldKey,
            parentFieldValue: parentFieldValue,
            parentFieldTitle: parentFieldTitle,
            parentUrlQueryParam: parentUrlQueryParam,
            collapsed: true,
            children: [],
            childrenTitles: "",
          });
        });

        if (gfm.numLevels > 1) {
          // attach children
          _.forEach(_.orderBy(data, ["level"], ["desc"]), (fd) => {
            if (fd.level < gfm.numLevels) {
              fd.children = _.flatMap(
                _.filter(data, (fdi) => {
                  return fdi.parentFieldValue === fd.value;
                }),
                (fdi) => {
                  return _.union(
                    [
                      _.pick(fdi, [
                        "value",
                        "level",
                        "title",
                        "urlQueryParam",
                        "numResults",
                      ]),
                    ],
                    fdi.children,
                  );
                },
              );

              fd.childrenTitles = _.join(_.map(fd.children, "title"), " ");
            }
          });

          // attach parent
          _.forEach(data, (fd) => {
            if (fd.level > 1) {
              fd.parent = _.find(data, (fdi) => {
                return fdi.value === fd.parentFieldValue;
              });
            }
          });
        }

        // CF-1048: filter out country level 2 data
        if (this.filterEntityId === "filterCountry") {
          data = _.reject(data, (d) => {
            return d.level > 1;
          });

          [
            {
              title_En: "Applicants or project partners",
              title_De: "Gesuchstellende oder Projektpartnerschaften",
              title_Fr: "Requérant·e·s ou partenaires de projet",
              value: "applicants",
            },
            {
              title_En: "Mobility fellowships",
              title_De: "Mobilitätsstipendien",
              title_Fr: "Bourses de mobilité",
              value: "fellowship",
            },
            {
              title_En: "Research collaborations",
              title_De: "Forschungszusammenarbeiten",
              title_Fr: "Collaborations de recherche",
              value: "collaboration",
            },
          ].forEach((countryType) => {
            const entry = Object.assign(
              {
                Entity: "Filter_Country_Type",
                level: 99,
                filterSolrField: "CountryIsoCodeType_mvf",
                numResults: 0,
                filterEntityId: "filterCountryType",
                urlQueryParam: "country-type",
                entityTitleKey: "CountryType",
                collapsed: true,
                children: [],
                childrenTitles: "",
              },
              countryType,
            );
            entry.title = this.objectAttributeInLanguage(entry, "title");

            data.push(entry);
          });
        }

        // console.log(data);
        // debugger;

        // process checked values
        _.forEach(this.grantCheckedFilterValues, (cfv) => {
          let entry = _.find(data, { value: cfv });

          if (entry) {
            if (!this.isMobileOrTablet) {
              this.collapsed = false;
            }

            while (entry.parentFieldValue) {
              entry = _.find(data, { value: entry.parentFieldValue });
              if (entry) {
                entry.collapsed = false;
              }
            }
          }
        });

        if (this.filterEntityId === "filterCall") {
          data = data.map((fd) => {
            // DSS-447: add title for level 1 for calls
            if (fd.level === 1 && fd.CallDecisionYear) {
              if (this.selectedLanguage === "de") {
                fd.title = `${fd.value} Entscheidungen`;
              } else if (this.selectedLanguage === "fr") {
                fd.title = `${fd.value} Décisions`;
              } else {
                fd.title = `${fd.value} Decisions`;
              }
            } else if (fd.CallDecisionYear) {
              if (this.selectedLanguage === "de") {
                fd.parentFieldTitle = `${fd.parentFieldValue} Entscheidungen`;
              } else if (this.selectedLanguage === "fr") {
                fd.parentFieldTitle = `${fd.parentFieldValue} Décisions`;
              } else {
                fd.parentFieldTitle = `${fd.parentFieldValue} Decisions`;
              }
            }
            return fd;
          });
          let [level1, level2] = _.partition(
            data.filter((d) => d.title),
            { level: 1 },
          );
          this.filterData = _.orderBy(level1, ["value"], ["desc"]).concat(
            _.orderBy(level2, ["numResults"], ["desc"]),
          );
        } else if (this.filterEntityId === "filterCountry") {
          let [level1, level2] = _.partition(data, { level: 1 });

          level2 = level2.map((d) => {
            if (d.CountryIsoCodeType) {
              const codeType = d.CountryIsoCodeType.substring(3);
              if (codeType === "applicants") {
                d.level2SortField = 1;
              } else if (codeType === "fellowship") {
                d.level2SortField = 2;
              } else if (codeType === "collaboration") {
                d.level2SortField = 3;
              } else {
                d.level2SortField = 99;
              }
            }
            return d;
          });
          this.filterData = _.orderBy(level1, ["numResults"], ["desc"]).concat(
            _.orderBy(level2, ["level2SortField"], ["asc"]),
          );
        } else {
          this.filterData = _.orderBy(data, ["numResults"], ["desc"]);
        }

        // log.warn('***********************', this.filterEntityId);
        // log.debug(this.filterData);
        this.updateGrantFilterValueCache(this.filterData);
      }
    },

    toggleSearchFilter: function (filterStatusEntry) {
      this.$emit("toggle-search-filter", filterStatusEntry);
    },

    selectDateValueChanged: function () {
      let minYear = this.selectedMinYear;
      let maxYear = this.selectedMaxYear;
      if (minYear > maxYear) {
        minYear = maxYear;
      }
      if (minYear === this.minYear) {
        minYear = "*";
      }
      if (maxYear === this.maxYear) {
        maxYear = "*";
      }
      this.$emit(
        "toggle-date-filter",
        this.filterEntityEntry.urlQueryParams[0],
        minYear,
        maxYear,
      );
    },

    filterShowAll: function (filterStatusEntry) {
      let filterEntityId = this.filterEntityId;
      if (filterStatusEntry) {
        filterEntityId = filterStatusEntry.filterEntityId;
      }
      this.$emit("filter-show-all", filterEntityId, this.filterData);
    },

    resetFilterInput: function () {
      log.debug("resetFilterInput clicked");
      this.filterInput = "";
      this.filterInputPerson = "";
      this.solrResponse = this.outerSolrResponse;
      if (this.filterEntityEntry.type === "person") {
        this.loadPersonFilterData();
      }
    },

    debounceFilterInput: _.debounce(function (val) {
      this.filterInput = val;

      if (this.filterEntityEntry.type === "person") {
        this.filterInputPerson = _.trim(this.filterInput);
        const nameParts = splitTrimWords(this.filterInputPerson);

        if (nameParts.length > 0) {
          // remove q-entry for this filter entity to get results for all persons
          const solrField = this.filterEntityEntry.grantSolrFields[0];
          let solrQueryData = _.assign({
            rows: "0",
            facet: true,
            "facet.limit": 20,
            "facet.field": solrField,
            q: _.join(_.values(_.omit(this.outerSolrQueryQ, solrField)), "+AND+"),
          });

          const nameField = this.filterEntityEntry.nameField;

          let q = "";
          _.forEach(nameParts, (namePart) => {
            q += `+AND+(${nameField}:*${_.upperFirst(
              namePart,
            )}*+OR+${nameField}:*${namePart}*)`;
          });
          solrQueryData.q += q;

          if (this.axiosCancelTokenSource) {
            this.axiosCancelTokenSource.cancel("make new search");
            this.axiosCancelTokenSource = null;
          }
          this.axiosCancelTokenSource = axios.CancelToken.source();

          makeSolrPostRequest(solrQueryData, this.axiosCancelTokenSource.token).then(
            (response) => {
              this.solrResponse = response.data;
              this.loadFilterData();
            },
          );
        }
      }
    }, 500),

    checkCollapsible(filterStatusEntry) {
      return this.asPopout && filterStatusEntry.children.length > 0;
    },

    toggleFold: function (filterStatusEntry) {
      log.debug(
        "toggleFold clicked",
        filterStatusEntry.collapsible,
        filterStatusEntry.value,
      );

      if (this.checkCollapsible(filterStatusEntry)) {
        this.filterData = this.filterData.map((fd) => {
          if (fd.value === filterStatusEntry.value) {
            fd.collapsed = !fd.collapsed;
          }

          return fd;
        });
      }
    },

    clickEntry: function () {
      log.debug("clickEntry", this.screenSize);

      if (this.isMobile) {
        log.debug("clickEntry on mobile");
        this.filterShowAll();
      } else {
        log.debug("clickEntry on desktop or tablet");
        if (!this.asPopout) {
          this.collapsed = !this.collapsed;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.grant-search-filter-entry.popout {
  padding-left: 40px;
  padding-right: 32px;
}

.entry {
  display: flex;
  padding-top: 12px;
  cursor: pointer;

  .entry__title {
    font-size: 16px;
    font-weight: 700;
    flex: 1 1 auto;
    display: flex;
    line-height: 32px;

    &:hover {
      color: $snf-blue-font;
    }

    .badge {
      @include badge(32px);
    }
  }

  .entry-checkbox {
    margin-top: 1px;
    width: 18px;
    height: 18px;
    flex: 0 0 24px;
  }

  .entry__toggle {
    width: 18px;
    height: 18px;
    flex: 0 0 24px;
    text-align: right;

    img {
      @include snf-blue-font-filter();
      width: 14px;
    }
  }
}

.entry__toggle.popout {
  display: none;
}

.date-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $snf-blue-font !important;
  }

  .grant-select {
    border: 1px solid $snf-gray-medium !important;
    border-radius: unset !important;
  }
}

.show-more {
  color: $snf-blue-font;
  margin-top: -8px;
  margin-bottom: 16px;
}

.filter-input-search-form {
  position: relative;
  margin: 16px 0;

  .search-input {
    border-radius: 0;
    height: 44px;
  }

  .filter-input-search-button {
    position: absolute;
    top: 0;
    right: 0;

    padding: 12px 1em;
    text-transform: none;
    border: none;
    cursor: pointer;
    color: $snf-blue-font;
    background: none;

    &:hover {
      color: black;
    }
  }
}

.reset-entity-filters-button {
  margin-bottom: 16px;
}
</style>
