<template>
  <div>
    <div
      v-if="
        filterEntityEntry && filterEntityEntry.id === 'filterCountry' && level === 1
      "
      class="country-type"
    >
      <div
        class="entry-holder"
        v-for="filterStatusEntry in filterEntriesForCountryType"
        :key="filterStatusEntry.value"
      >
        <div>
          <div
            class="entry"
            @click.stop="$emit('toggle-search-filter', filterStatusEntry)"
          >
            <div class="entry-checkbox">
              <img
                v-if="isEntryChecked(filterStatusEntry)"
                src="/img/checkbox-checked.svg"
                alt="checked"
              />
              <img v-else src="/img/checkbox-unchecked.svg" alt="unchecked" />
            </div>

            <div class="entry-text">
              <span>{{ filterStatusEntry.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="entry-holder"
      :key="filterStatusEntry.id"
      v-for="(filterStatusEntry, index) in filterEntriesForLevel"
      :style="{ 'margin-left': (level > 1 ? (asPopout ? 32 : 16) : 0) + 'px' }"
    >
      <div
        v-if="
          showAll ||
          (filterStatusEntry.level === 1 && index < showLimit) ||
          filterStatusEntry.level > 1 ||
          isEntryChecked(filterStatusEntry)
        "
      >
        <div
          @click.stop="$emit('toggle-search-filter', filterStatusEntry)"
          class="entry"
          v-show="showEntryByFilter(filterStatusEntry)"
          v-if="showEntry(filterStatusEntry)"
        >
          <div class="entry-checkbox">
            <img
              v-if="entryChecked(filterStatusEntry)"
              src="/img/checkbox-checked.svg"
              alt="checked"
            />
            <img
              v-else-if="anyChildrenChecked(filterStatusEntry)"
              src="/img/checkbox-half.svg"
              alt="checked-children"
            />
            <img v-else src="/img/checkbox-unchecked.svg" alt="unchecked" />
          </div>

          <div class="entry-text">
            <span v-html="highlightString(filterStatusEntry.title, filterInput)"></span>
            <!-- prettier-ignore -->
            <span v-if="!filterStatusEntry.hideNumResults">
              <span v-if="filterEntityEntry.id === 'filterCountry'">
                (<span v-show="!showLoader">{{ numberToStringPandemic(entryNumResults(filterStatusEntry)) }}</span>)
              </span>
              <span v-else>
                ({{ numberToStringPandemic(entryNumResults(filterStatusEntry)) }})
              </span>
            </span>
          </div>

          <div
            class="entry-toggle"
            @click.stop="$emit('toggle-fold', filterStatusEntry)"
          >
            <span v-if="!checkCollapsible(filterStatusEntry)"></span>
            <img
              v-else-if="filterStatusEntry.collapsed"
              src="/img/arrow-down-light.svg"
              alt="fold"
            />
            <img v-else src="/img/arrow-up-light.svg" alt="fold" />
          </div>
        </div>

        <GrantSearchFilterEntry
          v-show="showEntryChildren(filterStatusEntry)"
          :filter-data="filterData"
          :level="level + 1"
          :filter-input="filterInput"
          :parent-field-key="filterStatusEntry.filterSolrField"
          :parent-field-value="filterStatusEntry.value"
          :as-popout="asPopout"
          @toggle-search-filter="toggleSearchFilter"
          @toggle-fold="toggleFold"
          @filter-show-all="filterShowAll"
        ></GrantSearchFilterEntry>
      </div>
      <div
        v-if="showSearchPersonButton(filterStatusEntry, index)"
        class="entry search-person"
        @click="$emit('filter-show-all', filterStatusEntry)"
      >
        <div class="entry-checkbox">
          <img src="/img/search.svg" alt="search" />
        </div>
        <div class="entry-text">
          {{ cmsTranslationByKey("FilterSearchPerson") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from "loglevel";
import { mapGetters } from "vuex";
import { cmsTranslationMixin, highlighterMixin } from "@/mixins";
import * as _ from "lodash";
import { firstIndexOfMultiple, GRANT_FILTER_MAPPING } from "@/utils";

export default {
  name: "GrantSearchFilterEntry",
  mixins: [cmsTranslationMixin, highlighterMixin],
  props: {
    level: {
      type: Number,
      default: 1,
    },
    filterData: {
      type: Array,
      default: () => [],
    },
    parentFieldKey: {
      type: String,
      default: "",
    },
    parentFieldValue: {
      type: null,
      default: "",
    },
    parentFieldTitle: {
      type: String,
      default: "",
    },
    filterInput: {
      type: String,
      default: "",
    },
    filterEntityEntry: {
      type: Object,
      default: null,
    },
    asPopout: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: function () {
    return {
      showAll: this.asPopout,
      showLimit: 5,
    };
  },
  watch: {},
  mounted: function () {
    log.debug("GrantSearchFilterEntryLevel mounted");

    if (this.filterEntityEntry) {
      const gfm = GRANT_FILTER_MAPPING.find((e) => e.id === this.filterEntityEntry.id);
      if (gfm?.showLimit) {
        this.showLimit = gfm.showLimit;
      }
    }
  },
  computed: {
    ...mapGetters(["selectedLanguage", "grantCheckedFilterValues"]),
    filterEntriesForLevel: function () {
      if (
        !this.asPopout &&
        this.filterEntityEntry &&
        this.filterEntityEntry.id === "filterFundingInstrument" &&
        this.level === 1
      ) {
        // CF-1048: show flat funding instruments on main page
        return _.take(
          _.orderBy(
            this.filterData
              .filter((e) => {
                return e.children.length === 0 && e.numResults > 0;
              })
              .map((e) => {
                return Object.assign({}, e, { displayLevel: 1 });
              }),
            ["numResults"],
            ["desc"],
          ),
          5,
        );
      }

      return this.valuesForLevel(
        this.filterData,
        this.level,
        this.parentFieldKey,
        this.parentFieldValue,
      );
    },

    showLessButton: function () {
      return this.showAll && this.filterEntriesForLevel.length > this.showLimit;
    },

    filterEntriesForCountryType: function () {
      return this.filterData.filter((entry) => entry.level === 99);
    },
  },
  methods: {
    valuesForLevel(data, level, parentFieldKey, parentFieldValue) {
      if (level > 1) {
        return _.filter(data, (sfd) => {
          return sfd.level === level && sfd[parentFieldKey] === parentFieldValue;
        });
      }

      return _.filter(data, { level: 1 });
    },

    showSearchPersonButton: function (filterStatusEntry, index) {
      return (
        !this.showAll &&
        filterStatusEntry.level === 1 &&
        this.filterEntriesForLevel.length > this.showLimit &&
        this.filterEntityEntry.type === "person" &&
        index >= this.filterEntriesForLevel.length - 1
      );
    },

    toggleSearchFilter: function (filterStatusEntry) {
      this.$emit("toggle-search-filter", filterStatusEntry);
    },

    toggleFold: function (filterStatusEntry) {
      this.$emit("toggle-fold", filterStatusEntry);
    },

    filterShowAll: function (filterStatusEntry) {
      this.$emit("filter-show-all", filterStatusEntry);
    },

    entryNumResults: function (filterStatusEntry) {
      if (
        this.filterEntityEntry &&
        this.filterEntityEntry.id === "filterCountry" &&
        this.level === 1
      ) {
        // country filter, check for
        const queryCountryType = this.$route.query["country-type"];
        if (
          queryCountryType &&
          ["collaboration", "fellowship", "applicants"].includes(queryCountryType)
        ) {
          const countryValue = filterStatusEntry.value;
          const childValue = `${countryValue}-${queryCountryType}`;
          const child = _.find(filterStatusEntry.children, { value: childValue });
          if (child) {
            return child.numResults || 0;
          }
        }
      }
      return filterStatusEntry.numResults;
    },

    isEntryChecked: function (filterStatusEntry) {
      return filterStatusEntry && this.isEntryValueChecked(filterStatusEntry.value);
    },

    isEntryValueChecked: function (entryValue) {
      return _.includes(this.grantCheckedFilterValues, entryValue);
    },

    checkCollapsible(filterStatusEntry) {
      return (
        this.asPopout && filterStatusEntry.children.length > 0 && !this.filterInput
      );
    },

    anyChildrenChecked: function (filterStatusEntry) {
      return _.intersection(
        this.grantCheckedFilterValues,
        _.map(filterStatusEntry.children, "value"),
      ).length;
    },

    allChildrenChecked: function (filterStatusEntry) {
      if (filterStatusEntry.children.length > 0) {
        const checkedChildren = _.intersection(
          this.grantCheckedFilterValues,
          _.map(filterStatusEntry.children, "value"),
        );
        return checkedChildren.length === filterStatusEntry.children.length;
      }
      return this.isEntryChecked(filterStatusEntry);
    },

    entryChecked: function (filterStatusEntry) {
      const parentChecked = this.isEntryChecked(filterStatusEntry?.parent);
      const grandParentChecked = this.isEntryChecked(filterStatusEntry?.parent?.parent);
      return (
        this.isEntryChecked(filterStatusEntry) || parentChecked || grandParentChecked
      );
    },

    anyParentChecked: function (filterStatusEntry) {
      return this.isEntryValueChecked(filterStatusEntry.parentFieldValue);
    },

    showEntry: function (filterStatusEntry) {
      if (this.asPopout) {
        return true;
      } else {
        return (
          filterStatusEntry.level === 1 ||
          filterStatusEntry.displayLevel === 1 ||
          this.isEntryChecked(filterStatusEntry) ||
          this.anyChildrenChecked(filterStatusEntry)
        );
      }
    },

    showEntryByFilter: function (filterStatusEntry) {
      if (this.filterInput) {
        if (filterStatusEntry.filterEntityId === "filterCountry") {
          // only for country retain second level while filtering, see also CF-1016
          return (
            firstIndexOfMultiple(filterStatusEntry.title, this.filterInput) >= 0 ||
            firstIndexOfMultiple(filterStatusEntry.childrenTitles, this.filterInput) >=
              0 ||
            firstIndexOfMultiple(
              filterStatusEntry.parentFieldTitle,
              this.filterInput,
            ) >= 0
          );
        }

        return (
          firstIndexOfMultiple(filterStatusEntry.title, this.filterInput) >= 0 ||
          firstIndexOfMultiple(filterStatusEntry.childrenTitles, this.filterInput) >= 0
        );
      }

      return true;
    },

    showEntryChildren: function (filterStatusEntry) {
      if (this.asPopout) {
        if (this.filterInput) {
          return true;
        }

        return !filterStatusEntry.collapsed;
      } else {
        // show selected entries but only if not all are checked
        return !this.allChildrenChecked(filterStatusEntry);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.country-type {
  border-bottom: 1px solid $snf-gray-medium;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.entry {
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    color: $snf-blue-font;
  }
}

.entry-checkbox {
  margin-top: 1px;
  width: 18px;
  height: 18px;
  flex: 0 0 24px;
}

.entry-text {
  flex: 1 1 auto;
  padding: 0 4px 0 0;
}

.entry-toggle {
  width: 18px;
  height: 18px;
  flex: 0 0 24px;
  text-align: right;

  img {
    @include snf-blue-font-filter();
    width: 14px;
  }
}

.search-person {
  color: $snf-blue-font;
  margin-bottom: 16px;
}

.last {
  margin-bottom: 16px;
}

@include mobile() {
  .entry {
    padding: 12px 0;
    border-bottom: 1px solid $snf-gray-light;
  }
}
</style>
